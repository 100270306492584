import { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import storageSession from '../util/storage';

// import flagsImage from './flag-icons.png';
import './google.css';
import './flag-icons.css';

const TranslateGoogle = () => {
  const languageList = { pl: 'polski', en: 'angielski', de: 'niemiecki' };

  const { t } = useTranslation("lang");

  const [showList, setShowList] = useState(false);
  const [showLang, setShowLang] = useState(false);
  const refList = useRef();

  const onBlurList = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (refList.current && !refList.current.contains(document.activeElement)) {
        setShowList(false);
      }
    }, 100);
  };

  const googleTranslateElementInit = function () {
    console.log("Translate element init");
    new window.google.translate.TranslateElement({
      pageLanguage: 'pl',
      includedLanguages: 'pl,en,de',
      autoDisplay: 'false',
      layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL
      // layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT 
    }, 'b2b_google_translate_element');
  };

  const initNormal = () => { // return interval Id
    console.log("Translate normal init");

    return setInterval(function() {
      const selectLang = document.querySelector("div#b2b_google_translate_element select");
      if (selectLang == undefined) return;

      console.log("Translate interval", interval);
      clearInterval(interval);
      setShowLang(true);

      setTimeout(function() {
          const textNode = document.querySelector("div#b2b_google_translate_element > div > div").nextSibling;
          // console.log(textNode);
          if (textNode) textNode.parentNode.removeChild(textNode);
      }, 40);
    }, 1000);
  };

  const initObserver = () => {
    console.log("Translate observer init");
    const googleTranslateDiv = document.querySelector('div#b2b_google_translate_element');
    const googleTargetElem = 'goog-te-combo';

    let translateObserver = new MutationObserver((mutationsList) => {
      mutationsList.some(function(mutation) {
        if (mutation.type !== "childList" || mutation.target.className !== googleTargetElem) {
          return false;
        }

        console.log("Translate mutate");

        translateObserver.disconnect();
        translateObserver = null;

        setTimeout(function() {
          setShowLang(true);
        }, 100);

        return true;
      });
    });

    translateObserver.observe(googleTranslateDiv, {
      childList: true,
      subtree: true,
    });
  };

  const removeGoogleTranslate = () => {
    const googleScript = document.querySelectorAll('#script_googletranslate, #goog-gt-tt, body > iframe, body > div:not(#root)');

    if (googleScript != undefined) {
      googleScript.forEach(el => el.remove());
    }

    if (window?.google != undefined)
      window.google = undefined;
  };

  const existsGoogleTranslate = () => {
    if (document.querySelector('#goog-gt-tt') == undefined) return false;
    if (document.querySelector('#goog-gt-tt') == null) return false;

    return true;
  };

  useEffect(() => {
    if (showLang == true) {
      return;
    }

    if (existsGoogleTranslate()) {
      if (showLang == false) {
        // googleTranslateElementInit();
        setShowLang(true);
      }
      return;
    }

    console.log('%cGOGGLE_LANG', 'color:blueviolet', showLang);

    removeGoogleTranslate();
    let interval = undefined;

    if (typeof MutationObserver != 'function')
      interval = initNormal();
    else
      initObserver();

    window.googleTranslateElementInit = googleTranslateElementInit;

    const addScript = document.createElement('script');
    addScript.id = 'script_googletranslate';
    addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    document.body.appendChild(addScript);

    // setTimeout(function() {
    //     const currentLanguage = document.documentElement.lang;
    //     const inputt = $('.slidedownLanguage input[name="chooseLanguage"][value="'+currentLanguage+'"]');
    //     inputt.prop('checked', true);
    // }, 1000);
    return () => {
      interval != undefined && clearInterval(interval);
      // removeGoogleTranslate();
    }
  }, []);

  const onLanguageSelect = (lang) => {
    setShowList(false);
    storageSession.set('global-lang', lang);
    console.log("LANG change", lang, document.documentElement.lang);

    if (document.documentElement.lang === lang) return;

    if (lang === 'pl') {
      const iframe = document.getElementsByClassName('goog-te-banner-frame')[0] || document.getElementById(':1.container');
      if (!iframe) return;

      const iframeInner = iframe.contentDocument || iframe.contentWindow.document;
      // const btnClose = iframeInner.querySelector('a.goog-close-link');
      const btnClose = iframeInner.getElementById(':1.close');

      btnClose.dispatchEvent(new Event('click'));
      return;
    }

    const trans = document.querySelector('select.goog-te-combo');
    if (trans == null) {
      // googleTranslateElementInit();
      // document.documentElement.lang = lang;
      return;
    }

    trans.value = lang === 'pl' ? 'auto' : lang;
    trans.dispatchEvent(new Event('change'));
  };

  return (
    <div>
      <div className="flex flex-end" id="b2b_sitetranslate_main">
        {showLang && (
          <div className="relative inline-block text-left">
            <div>
              <button
                onClick={() => setShowList(!showList)}
                onBlur={onBlurList}
                type="button"
                className="flex flex-row justify-center gap-1 text-primary"
              >
                <i
                  className="w-6 h-4 mr-1 flags fflag fflag-html"
                  // style={{ backgroundImage: `url(${flagsImage})` }}
                />
                {/* <span>{languageList[chooseLang]}</span> */}
                <svg className="w-4 h-4 relative top-0.5" viewBox="0 0 24 24" fill="none">
                  <path d="M19 9L12 16L5 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </div>
            <div
              tabIndex={-1}
              ref={refList}
              onBlur={onBlurList}
              className={`origin-top-left absolute left-0 z-20 top-5 m-width-20 transition-opacity mt-1 ring-1 ring-black ring-opacity-5 right-0 
                rounded-md bg-white shadow-lg outline-none ${showList ? 'opacity-100 visible' : 'invisible opacity-0'}`}
              style={{ minWidth: '140px' }}
            >
              <div className="relative">
                <ul
                  tabIndex={0}
                  role="listbox"
                  aria-labelledby="language-label"
                  aria-activedescendant="language-0"
                  className="py-1 text-sm focus:outline-none sm:text-sm"
                >
                  <li className="px-3 py-2 text-xs text-gray-500 border-b border-gray-300 border-dashed">{t('Wybierz\u00A0język')}</li>
                  {[...Object.entries(languageList)]
                    .map(([key, value]) => (
                      <li
                        key={`google_language_choose_${key}`}
                        id={`google_language-${key}`}
                        role="option"
                        aria-selected="false"
                        className={`group relative py-2 pl-3 pr-6 cursor-default select-none hover:text-white 
                          hover:bg-primary ${key == document.documentElement.lang ? 'bg-primary text-white' : 'text-gray-700'}`}
                      >
                        <button
                          type="button"
                          onMouseDown={(e) => onLanguageSelect(key)}
                          className={`w-full outline-none`}
                        >
                          <div className="flex flex-row items-start gap-2" title={value}>
                            <div className={`flags fflag fflag-${key.toUpperCase()}`} />
                            <div className="text-sm lowercase whitespace-nowrap">
                              {t(value)}
                            </div>
                          </div>
                        </button>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="b2b_google_translate_element" className="invisible w-0 h-0 overflow-hidden" />
    </div>
  );
};

export default TranslateGoogle;
