import { useEffect, useState } from 'react'
import api from '../service/api';
import { checkMimeType } from '../util';


function useFileCache(func: string) {
  const fileList = {};

  useEffect(() => {
    return () => {
      Object.keys(fileList).forEach(key => {
        URL.revokeObjectURL(fileList[key]);
        delete fileList[key];
      });
    };
  }, []);

  const loadSticker = async (symbol, width = 40, num = 0) => {
    try {
      if (fileList[`${symbol}_${width}_${num}_STI`] !== undefined) {
        return fileList[`${symbol}_${width}_${num}_STI`];
      }

      let res = await api.fileCache.getArticleSticker(symbol, num, width);
      const urlImage = URL.createObjectURL(res.data);
      fileList[`${symbol}_${width}_${num}_STI`] = urlImage;

      return urlImage;
    } catch (err) {
      return null;
    }
  };

  const loadImage = async (symbol, width = 40, num = 0) => {
    try {
      if (fileList[`${symbol}_${width}_${num}_ZDJ`] !== undefined) {
        return fileList[`${symbol}_${width}_${num}_ZDJ`];
      }

      let res = await api.fileCache.getArticleImage(symbol, num, width);
      const urlImage = URL.createObjectURL(res.data);
      fileList[`${symbol}_${width}_${num}_ZDJ`] = urlImage;

      return urlImage;
    } catch (err) {
      return null;
    }
  };

  const loadDocument = async (symbol, num = 0) => {
    try {
      if (fileList[`${symbol}_${num}_PDF`] !== undefined) {
        const urlDocument = fileList[`${symbol}_${num}_PDF`];
        return urlDocument; // obj { url: '', mimeType: '' }
      }

      let res = await api.fileCache.getArticleDocument(symbol, num);
      const fileType = await checkMimeType(res.data);

      var file = new Blob([res.data], { type: fileType });
      const urlDocument = URL.createObjectURL(file);
      
      fileList[`${symbol}_${num}_PDF`] = { url: urlDocument, mimeType: fileType };
      return fileList[`${symbol}_${num}_PDF`];
    } catch (err) {
      console.log("Download file", err);
      return { url: null, mimeType: 'text/html' };
    }
  };

  const loadSlider = async (num = 0, id = "", width = 1024) => {
    try {
      if (fileList[`${num}_${id}_${width}_SLIDER`] !== undefined) {
        return fileList[`${num}_${id}_${width}_SLIDER`];
      }

      let res = await api.fileCache.getSliderImage(num, id, width);
      const urlImage = URL.createObjectURL(res.data);
      fileList[`${num}_${id}_${width}_SLIDER`] = urlImage;

      return urlImage;
    } catch (err) {
      return null;
    }
  };

  const loadLogo = async (symbol = '') => {
    try {
      if (fileList[`LOGO_${symbol}`] !== undefined) {
        return fileList[`LOGO_${symbol}`];
      }

      let res = await api.fileCache.getLogoImage();
      const urlImage = URL.createObjectURL(res.data);
      fileList[`LOGO_${symbol}`] = urlImage;

      return urlImage;
    } catch (err) {
      return null;
    }
  };

  return [loadImage, loadDocument, loadSlider, loadLogo, loadSticker];
};

export default useFileCache;
